import { Component, OnInit, ViewChild, EventEmitter, Output, Input, ViewContainerRef } from '@angular/core';

import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { MasterStateService } from 'src/app/modules/configuration/master-states/services/master-state.service';
import { MasterCountryService } from 'src/app/modules/configuration/master-countries/services/master-country.service';
import { MasterCityService } from 'src/app/modules/configuration/master-cities/services/master-city.service';
import { GlobalHelper } from 'src/app/helper/global.helper';


@Component({
  selector: 'app-upsert-master-csc',
  templateUrl: './upsert-master-csc.component.html',
  styleUrls: ['./upsert-master-csc.component.css'],
  providers: [MasterStateService, MasterCityService, MasterCountryService]
})
export class UpsertMasterCscComponent implements OnInit {
  @Input('options') options;
  @Output() modalSaveCB = new EventEmitter<string>();
  @ViewChild('masterCSC') masterCSC: NgbModalRef;

  modelAllCounties = [];
  modelAllStates = [];
  allCities = [];
  cityLoading = false;
  addrModel: any = {};
  modelFormLoading = false;

  constructor(private masterStateService: MasterStateService, private modalService: NgbModal, private masterCountryService: MasterCountryService,
    private masterCityService: MasterCityService, private viewContainerRef: ViewContainerRef, private globalHelper: GlobalHelper) { }

  ngOnInit() {
    this.modelAllCounties = this.options.modalDatas.allCountries;
    this.modelAllStates = this.options.modalDatas.allStates;
    const self = this, option = {
      centered: true
    };
    setTimeout(() => {
        self.addrModel.country_id = self.options.modalDatas.country_id || '';
        self.addrModel.state_id = self.options.modalDatas.state_id || '';
        self.options.modalRef = self.modalService.open(self.masterCSC, option);
        self.options.modalRef.result.then((result) => { }, (reason) => {
            if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
                self.getParentComponent().showModal = false;
                self.close();
            }
        });
    }, );
  }

  countryChange() {
    this.modelAllStates = [];
    this.addrModel.state_id = '';
    this.cityLoading = true;
    const self = this,
    paramsOptions = this.globalHelper.convertObjectToParams({ cid: this.addrModel.country_id });
    this.masterStateService.getModelLists({ params: paramsOptions }).subscribe((allStates: any) => {
      if (allStates && allStates.length) {
        self.modelAllStates = allStates;
      }
      self.cityLoading = false;
    }, error =>  self.cityLoading = false);
  }
  stateChange() {
    this.allCities = [];
        const self = this, paramsOptions = this.globalHelper.convertObjectToParams({ sid:  this.addrModel.state_id });
        this.masterCityService.getModelLists({params: paramsOptions}).subscribe((allCities: any) => {
            if (allCities && allCities.length) {
                self.allCities = allCities;
            }
        });
    }

  getParentComponent() {
    return this.viewContainerRef['_data'].componentView.component.viewContainerRef['_view'].component;
  }

  close() {
    this.getParentComponent().cscShowModal = false;
    this.options.modalRef.close();
  }

  addMasterCSCDatas(form) {
    this.modelFormLoading = true;
    const self = this, results: any = {},
    reqDatas = JSON.parse(JSON.stringify(this.addrModel));
    let subscribeRef;
    reqDatas['status'] = '1';
    if (this.options.modalDatas.type === 'city') {
        subscribeRef = this.masterCityService.createModel(reqDatas);
    } else if (this.options.modalDatas.type === 'state') {
        subscribeRef = this.masterStateService.createModel(reqDatas);
    } else if (this.options.modalDatas.type === 'country') {
        subscribeRef = this.masterCountryService.createModel(reqDatas);
    }
    subscribeRef.subscribe((resultDatas) => {
      self.modelFormLoading = false;
      results.resultDatas = resultDatas;
      results.allStates = self.modelAllStates;
      results.allCities = self.allCities;
      results.type = self.options.modalDatas.type;
      form.submitted = false;
      self.modalSaveCB.next(results);
      self.close();
    }, error => self.modelFormLoading = false);
  }



}
