import { Component, OnInit, OnChanges, AfterViewInit, Input, ViewChild, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap } from 'rxjs/operators';

import { SaleReturnBillsFormElementsComponent } from '../sale-return-bills-form-elements/sale-return-bills-form-elements.component';
import { SaleReturnItemsFormElementsComponent } from '../sale-return-items-form-elements/sale-return-items-form-elements.component';

import { ProductService } from '../../modules/configuration/products/services/product.service';
import { HsnCodeService } from '../../modules/configuration/hsn-codes/services/hsn-code.service';
import { SaleItemRemoveService } from '../../modules/pharmacy/sales/services/sale-item-remove/sale-item-remove.service';
import { SaleReturnsService } from 'src/app/modules/pharmacy/sale-returns/services/sale-returns.service';
import { SaleService } from 'src/app/modules/pharmacy/sales/services/sale/sale.service';
import { GlobalHelper } from '../../helper/global.helper';

@Component({
    selector: 'app-common-sale-form-elements',
    templateUrl: './common-sale-form-elements.component.html',
    styleUrls: ['./common-sale-form-elements.component.css']
})
export class CommonSaleFormElementsComponent implements OnInit, OnChanges, AfterViewInit {

    @ViewChild(SaleReturnBillsFormElementsComponent) saleReturnBillsFormElemts: SaleReturnBillsFormElementsComponent;
    @ViewChild(SaleReturnItemsFormElementsComponent) saleReturnItemsFormElemts: SaleReturnItemsFormElementsComponent;
    @Input('modelValue') modelValue;
    @Input('formRef') formRef;
    @Input('pageType') pageType;
    @Input('err') err;
    @Input('retBillAmt') retBillAmt;
    @Input('pharmacyIndentId') pharmacyIndentId = '';  // Initially should empty string. Because, We added condition based on that.
    amtChangeSTOID;
    overAllDisType = '';
    arrName = 'product_items';
    customFormCond = false;
    prodFieldsVal = true;
    laststBillLoading = {value: false}; // We are updating this loader in service file, Objects can only mutate in service file
    tempFinalAmt = 0;
    generic_Products = [];
    allHSNCodes = [];
    returnsaleLoading = false;
    billModel: any = {};
    paymentMode = this.globalHelper.getCurData('payMode');
    saleItemTimeoutId;
    saleItemSub = null;
    saleItemDeleteLoading = false;

    searchingBill: any = (text$: Observable < string > ) => text$.pipe(debounceTime(300), distinctUntilChanged(), switchMap(term => {
        term = term.trim();
        if (term !== '') {
            const self = this;
            this.modelValue.billLoading = true;
            return this.saleRTNService.billSearch({ searchValue: term, patientId: this.modelValue.patient_id }).pipe(map((response) => {
                self.modelValue.billLoading = false;
                return response;
            }), catchError(() => {
                self.modelValue.billLoading = false;
                return of([]);
            }));
        }
        return of([]);
    }))

    searchingProducts: any = (text$: Observable<string>) => text$.pipe(debounceTime(300), distinctUntilChanged(),
        switchMap((term: string) => {
            term = term.trim();
            if (term !== '') {
                this.modelValue[term] = true;
                const self = this, paramsOptions = this.globalHelper.convertObjectToParams({ search: term });
                return this.productService.productSearch({params: paramsOptions}).pipe(map((response) => {
                    self.modelValue[term] = false;
                    // tslint:disable-next-line:no-unused-expression
                    delete self.modelValue[term];
                    return response;
                }), catchError(() => {
                    self.modelValue[term] = false;
                    // tslint:disable-next-line:no-unused-expression
                    delete self.modelValue[term];
                    return of([]);
                }));
            }
            return of([]);
        }))

    billInFormatter = (x: { bill_no_with_patient: string }) => x.bill_no_with_patient;
    billOutFormatter = (x: { bill_no_with_patient: string }) => x.bill_no_with_patient;
    productInFormatter = (x: {sale_product_name_with_qty: string}) => x.sale_product_name_with_qty;
    productOutFormatter = (x: {sale_product_name_with_qty: string}) => x.sale_product_name_with_qty;

    constructor(private productService: ProductService,
        private saleItemRemoveService: SaleItemRemoveService, private hsnCodeService: HsnCodeService,
        private globalHelper: GlobalHelper, private saleRTNService: SaleReturnsService,
        private modelService: SaleService) {}

    ngOnInit() {
        if (this.modelValue.allowNewSale) {
            this.modelValue = {
                ...this.modelValue,
                ...{
                    total_item_sale_amount: '0.00',
                    total_item_discount_percent: 0,
                    total_item_discount_amount: '0.00',
                    total_item_amount: '0.00',
                    roundoff_amount: '0.00',
                    bill_amount: '0.00',
                    amount_received: '0.00',
                    latst_sales: [],
                    return_Bill: '',
                    sale_return_data: {
                        openNewRtnSale: false
                    }
                }
            };
            this.arrName = 'sale_items';
            this.modelValue.retBillAmt = this.retBillAmt;
        }
        this.getMasterRecords();
    }

    ngAfterViewInit () {
        this.inputFocusSelect();
    }

    ngOnChanges (changes: SimpleChanges) {
        if (_.get(changes, 'modelValue.currentValue.createSaleForIndent', false)) {
            const self = this;
            changes.modelValue.currentValue['product_items'].map(pData => {
                self.getIndentProductDetails(pData);
            });
        }
    }

    // For getting details for the indent
    getIndentProductDetails(item: any) {
        const self = this, paramsOptions = this.globalHelper.convertObjectToParams({ scope: 'sale' });
        this.productService.getModel(item.product_id, { params: paramsOptions }).subscribe((productDetails: any) => {
            if (productDetails) {
                if (productDetails.gst && productDetails.gst !== '-') {
                    item.cgst_percent = parseFloat((parseFloat(productDetails.gst.gst) / 2).toFixed(2));
                    item.sgst_percent = parseFloat((parseFloat(productDetails.gst.gst) / 2).toFixed(2));
                } else {
                    item.cgst_percent = 2.5;
                    item.sgst_percent = 2.5;
                }
                if (productDetails.hsn && productDetails.hsn.hsn_no) {
                    item.hsn_no = _.get(productDetails, 'hsn.hsn_no', '');
                    item.defaultHSN = item.hsn_no !== '';
                }
                item.sale_product_name_with_qty = productDetails.product.sale_product_name_with_qty;
                item.product_location = productDetails.product.product_location;
                item.generic_id = productDetails.product.generic_id;
                item.vat_percent =  _.get(productDetails, 'sales_vat.vat', 0);
                item.package_name = productDetails.salesPackageName;
                item.isExpirySoon = item.isExpiry = false;
                if (productDetails.product_batchs) {
                    item.productBatches = productDetails.product_batchs;
                    item.productBatches.map(batchDatas => {
                        batchDatas.isExpiry = self.globalHelper.compareDate(batchDatas.expiry_date) ? true : false;
                        if (batchDatas.product_expiry_month && !batchDatas.isExpiry && (self.globalHelper.getMomentDiff(batchDatas.expiry_date, 'months', new Date()) + 1) <= Number(batchDatas.product_expiry_month)) {
                            batchDatas.isExpirySoon = true;
                        } else {
                            batchDatas.isExpirySoon = false;
                        }
                        batchDatas.tempAvailableQty = JSON.parse(JSON.stringify(batchDatas.available_qty));
                    });
                }
                item.selectBatch = '';
                item.batch_id = '';
                item.expiry_date = '';
                item.mrp = '';
                item.taxable_value = '';
                item.tempTaxVal = 0;
                item.total_amount = '';
                // item.quantity = 0;
                item.discount_percentage = 0;
                item.discount_amount = 0;
                item.cgst_amount = '';
                item.sgst_amount = '';
                self.modelValue[self.arrName].map(() => {
                    self.updateBatchData('update');
                });
                if (`${item.available_qty}` === '0') {
                    item.genericByProd = '';
                    self.genericProducts(productDetails.product.generic_id, item.product_id);
                }
            }
        });
    }

    changePaymentType (formRef) {
        if (formRef.submitted) {
            formRef.submitted = false;
            setTimeout(() => {
                formRef.submitted = true;
            });
        }
    }
    // checkCardNo (cardNo) {
    // 	if (cardNo || cardNo === 0) {
    //         if (this.modelValue.card_type !== 'QR Code'
    //             && (cardNo <= 1000 && cardNo >= 9999)) {
    //             this.modelValue.cNoInvalid = true;
    //             return false;
    //         } else if (this.modelValue.card_type === 'QR Code'
    //             && (cardNo <= 1000000000 && cardNo >= 9999999999)) {
    //             this.modelValue.cNoInvalid = true;
    //             return false;
    //         }
    //     }
    //     this.modelValue.cNoInvalid = false;
    // }

    inputFocusSelect () {
        $('input').on('focus', function (ev) {
            $(this).select();
        });
        setTimeout(() => {
            $('input[type=number]').on('mousewheel', function(e) {
                $(this).blur();
            });
        }, 500);
    }

    selectedProduct($e, item) {
        this.prodFieldsVal = true;
        if ($e.preventDefault) { $e.preventDefault(); }
        if ($e.item) {
            item.selectProduct = $e.item;
            item.product_id = $e.item.product_id;
        } else {
            $e['sale_product_name_with_qty'] = $e.sale_product_name_with_qty;
            item.selectProduct = $e;
            item.product_id = $e.product_id;
        }
        const self = this, paramsOptions = this.globalHelper.convertObjectToParams({ scope: 'sale' });
        this.productService.getModel(item.product_id, { params: paramsOptions }).subscribe((productDetails: any) => {
            if (productDetails) {
                if (productDetails.gst && productDetails.gst !== '-') {
                    item.cgst_percent = parseFloat((parseFloat(productDetails.gst.gst) / 2).toFixed(2));
                    item.sgst_percent = parseFloat((parseFloat(productDetails.gst.gst) / 2).toFixed(2));
                } else {
                    item.cgst_percent = 0.00;
                    item.sgst_percent = 0.00;
                }
                if (productDetails.hsn && productDetails.hsn.hsn_no) {
                    item.hsn_no = productDetails.hsn.hsn_no;
                    item.defaultHSN = true;
                }
                item.available_qty = $e.item ? $e.item.available_qty : $e.available_qty;
                item.sale_product_name_with_qty = productDetails.product.sale_product_name_with_qty;
                item.product_location = productDetails.product.product_location;
                item.generic_id = productDetails.product.generic_id;
                item.vat_percent = productDetails.sales_vat.vat;
                item.package_name = productDetails.salesPackageName;
                item.isExpirySoon = item.isExpiry = false;
                if (productDetails.product_batchs) {
                    item.productBatches = productDetails.product_batchs;
                    item.productBatches.map(batchDatas => {
                        batchDatas.isExpiry = self.globalHelper.compareDate(batchDatas.expiry_date) ? true : false;
                        if (batchDatas.product_expiry_month && !batchDatas.isExpiry && (self.globalHelper.getMomentDiff(batchDatas.expiry_date, 'months', new Date()) + 1) <= Number(batchDatas.product_expiry_month)) {
                            batchDatas.isExpirySoon = true;
                        } else {
                            batchDatas.isExpirySoon = false;
                        }
                        batchDatas.tempAvailableQty = JSON.parse(JSON.stringify(batchDatas.available_qty));
                    });
                }
                item.selectBatch = '';
                item.batch_id = '';
                item.expiry_date = '';
                item.mrp = '';
                item.taxable_value = '';
                item.tempTaxVal = 0;
                item.total_amount = '';
                item.quantity = 0;
                item.discount_percentage = 0;
                item.discount_amount = 0;
                item.cgst_amount = '';
                item.sgst_amount = '';
                self.modelValue[self.arrName].map((prodDatas, prodIndex) => {
                    self.updateBatchData('update');
                });
                if (`${item.available_qty}` === '0') {
                    item.genericByProd = '';
                    self.genericProducts(productDetails.product.generic_id, item.product_id);
                }
            }
        });
    }

    batchChange (item, prodIndex) {
        this.prodFieldsVal = true;
        if (item.selectBatch.batch_id) {
            const batchIndex = this.modelValue[this.arrName].map(prodItem => prodItem.batch_id).indexOf(item.selectBatch.batch_id);
            if (batchIndex === -1) {
                const itemBatch = item.selectBatch;
                if (itemBatch && itemBatch.batch_details) {
                    const IBDetails = itemBatch.batch_details;
                    item.expiry_date = IBDetails.substring(IBDetails.search('[(]') + 1, IBDetails.search('[)]'));
                    item.batch_details = IBDetails;
                    item['availableQty'] = itemBatch.available_qty;
                    item.expiry_date = itemBatch.expiry_date;
                    item.batch_no = itemBatch.batch_no;
                    item.batch_id = itemBatch.batch_id;
                    item.package_name = item.selectBatch.package_name;
                    item.package_unit = item.selectBatch.package_unit;
                    item.mrp = parseFloat(itemBatch.per_unit_price);
                    item.taxable_value = '';
                    item.tempTaxVal = 0;
                    item.total_amount = '';
                    item.quantity = item.quantity || 0;
                    item.discount_percentage = 0;
                    item.discount_amount = 0;
                    item.cgst_amount = '';
                    item.sgst_amount = '';
                    item.prevBatch = item.selectBatch;
                    item.isExpiry = this.globalHelper.compareDate(item.expiry_date) ? true : false;
                    item.quantity = item.isExpiry ? 0 : item.quantity;
                    if (itemBatch.product_expiry_month && !item.isExpiry && (this.globalHelper.getMomentDiff(item.expiry_date, 'months', new Date()) + 1) <= Number(itemBatch.product_expiry_month)) {
                        item.isExpirySoon = true;
                    } else {
                        item.isExpirySoon = false;
                    }
                    if (item.quantity || `${item.quantity}` === '0') {
                        this.amountChanges('Qty', item);
                    }
                }
            } else {
                if (this.modelValue[this.arrName][prodIndex].productBatches.length === 1) {
                    this.modelValue[this.arrName].splice(prodIndex, 1);
                }
                this.globalHelper.toastrOpen('W', 'This batch combination has already been taken.');
                $(`#saleQty${batchIndex}`).focus();
                setTimeout(() => {
                    if (item.prevBatch) {
                        item.selectBatch = item.prevBatch;
                    } else {
                        item.selectBatch = '';
                    }
                });
            }
        }
    }

    // qty update by no of days value changes
    async qtyUpdatePres (value) {
        if (value) {
            // Below condition for remove the prefix zero from this value.Because, we have added maxlength.
            this.modelValue.number_of_days = `${value[0]}` === '0' ? value.slice(1, value.length) : value;
            await this.modelValue[this.arrName].map(async prodItem => {
                if (prodItem.frequency && !prodItem.isExpiry) {
                    prodItem.quantity = await this.calQuantity(prodItem.frequency, this.modelValue.number_of_days, prodItem.prod_description_name);
                    await this.amountCalculation('Qty', prodItem);
                }
            });
        } else {
            this.modelValue.number_of_days = 0;
            await this.modelValue[this.arrName].map(async prodItem => {
                if (prodItem.frequency && !prodItem.isExpiry) {
                    prodItem.quantity = 0;
                    await this.amountCalculation('Qty', prodItem);
                }
            });
        }
    }

    // quantity calculation depends on freq by no of day changes
    calQuantity (freq, nofDays, descName) {
        const freqCount = freq.split('-').reduce((tot, curFreq) => parseFloat(tot) + parseFloat(curFreq)),
        qtyCalcDescNames = ['tablet', 'capsule', 'tablets', 'capsules'];
        if (descName && qtyCalcDescNames.indexOf(descName.toLowerCase()) !== -1 && freqCount) {
            return Math.round(parseFloat(nofDays) * parseFloat(freqCount));
        }
        return 1;
    }

    amountChanges (type, item) {
        clearTimeout(this.amtChangeSTOID);
        const self = this;
        this.amtChangeSTOID = setTimeout(() => {
            self.amountCalculation(type, item);
        }, 300);
    }

    amountCalculation (type, item) {
        if (this.pageType === 'Update') {
            const pQty = parseFloat(item.quantity),
                pOldQty = parseFloat(item.oldAttributeQuantity);
            this.customFormCond = item.noStock = item.qtyExceed = false;
            if (pOldQty > pQty) {
                if (parseFloat(item.total_returned_quantity) > pQty) {
                    this.customFormCond = item.qtyExceed = true;
                }
            } else {
                const curQty = (pQty - pOldQty); // *parseFloat(item.package_unit);
                if (curQty > parseFloat(item.availableQty)) {
                    this.customFormCond = item.noStock = true;
                }
            }
        }
        if (item.quantity !== undefined && item.quantity !== null) {
            item.quantity = item.quantity || 0;
            if (item.cgst_percent && item.sgst_percent) {
                this.gstBasedCalculations(type, item);
            } else {
                item.cgst_percent = item.sgst_percent = 0.00;
                this.gstBasedCalculations(type, item);
            }
        }
        this.updateBatchData('update');
        this.amtChangeSTOID = false;
    }

    updateBatchData (type) {
        this.modelValue[this.arrName].map((item, key) => {
            const item_product_id = item.product_id;
            const item_expiry_date = item.expiry_date;
            const item_batch_no = item.batch_no;
            const item_batch_id = item.batch_id;
            const item_batch_details = item.batch_details;
            const item_quantity = item.quantity;
            this.modelValue[this.arrName].map((item1, key1) => {
                if (key !== key1) {
                    if (item_product_id === item1.product_id && item1.productBatches) {
                        item1.productBatches.map((batch) => {
                            if ((batch.batch_id === item_batch_id) && (batch.batch_no === item_batch_no) &&
                                (batch.expiry_date === item_expiry_date)) {
                                if (type === 'update') {
                                    batch.available_qty = batch.tempAvailableQty - item_quantity;
                                    const batch_qty = batch.batch_details.split('/');
                                    batch_qty[1] = batch.tempAvailableQty - item_quantity;
                                    batch.batch_details = batch_qty[0] + '/' + (batch_qty[1] < 0 ? 0 : batch_qty[1]);
                                } else {
                                    batch.available_qty = batch.tempAvailableQty;
                                    const batch_qty = batch.batch_details.split('/');
                                    batch_qty[1] = batch.tempAvailableQty;
                                    batch.batch_details = batch_qty[0] + '/' + batch_qty[1];
                                }
                            }
                        });
                    }
                }
            });
        });
    }

    gstBasedCalculations (type, item) {
        item.taxable_value = ((parseFloat(item.mrp) / (100 + parseFloat(item.cgst_percent) + parseFloat(item.sgst_percent)) * 100))
            * item.quantity;

        const itemTaxableAmount = item.taxable_value.toFixed(2);

        if (type === 'Qty') {
            item.tempTaxVal = itemTaxableAmount;
        }

        switch (type) {
            case '%':
                if (item.discount_percentage) {
                    item.discount_amount = (((item.discount_percentage / 100) * itemTaxableAmount)).toFixed(2).replace(/\.?0+$/, '');
                    this.updateResultAmounts(item);
                } else {
                    item.discount_amount = 0;
                    item.discount_percentage = 0;
                    this.updateResultAmounts(item);
                }
                break;
            case '$':
                if (item.discount_amount) {
                    item.discount_percentage = (((item.discount_amount * 100) / itemTaxableAmount)).toFixed(2).replace(/\.?0+$/, '');
                    this.updateResultAmounts(item);
                } else {
                    item.discount_amount = 0;
                    item.discount_percentage = 0;
                    this.updateResultAmounts(item);
                }
                break;
            case 'Qty':
                if (item.discount_percentage) {
                    item.discount_amount = (((item.discount_percentage / 100) * itemTaxableAmount)).toFixed(2).replace(/\.?0+$/, '');
                }
                this.updateResultAmounts(item);
                break;
            default:
                this.updateResultAmounts(item);
                break;
        }
    }

    updateResultAmounts (item) {
        if (item.discount_amount) {
            item.taxable_value = item.taxable_value - parseFloat(item.discount_amount);
        }
        item.cgst_amount = parseFloat(((item.taxable_value * item.cgst_percent) / 100).toFixed(2));
        item.sgst_amount = parseFloat(((item.taxable_value * item.sgst_percent) / 100).toFixed(2));
        // Math.abs() for remove - before 0
        item.total_amount = Math.abs((item.taxable_value + item.sgst_amount + item.cgst_amount).toFixed(2));
        item['vat_amount'] = ((parseFloat(item.total_amount) * item.vat_percent) / (100 + item.vat_percent)).toFixed(2);
        // Math.abs() for remove - before 0
        item.taxable_value = Math.abs(item.taxable_value.toFixed(2));
        this.getFinalTotalAmt();
    }

    getFinalTotalAmt () {
        const self = this;
        this.tempFinalAmt = 0;
        let allTaxableValues = 0;
        if (this.modelValue[this.arrName].length) {
            this.modelValue[this.arrName].map((prodDatas, prodIndex) => {
                allTaxableValues = allTaxableValues + parseFloat(prodDatas.taxable_value);
                self.tempFinalAmt = self.tempFinalAmt + parseFloat(prodDatas.total_amount || 0);
                if (prodIndex === self.modelValue[self.arrName].length - 1) {
                    self.modelValue.total_item_vat_amount = allTaxableValues.toFixed(2);
                    self.modelValue.total_item_sale_amount = self.tempFinalAmt.toFixed(2);
                    if (`${self.modelValue.total_item_discount_percent}` !== '0.00' && self.modelValue.total_item_discount_percent !== 0) {
                        self.modelValue.total_item_amount = self.modelValue.total_item_sale_amount;
                        self.finalDisChange(self.overAllDisType);
                    } else {
                        self.modelValue.total_item_amount = self.modelValue.total_item_sale_amount;
                        self.setFinalTotalAmt();
                    }
                }
            });
        } else {
            self.tempFinalAmt = 0;
            self.modelValue.total_item_vat_amount = 0;
            self.modelValue.total_item_sale_amount = 0;
            self.modelValue.total_item_amount = 0;
            self.setFinalTotalAmt();
        }
    }

    finalDisChange (type) {
        this.overAllDisType = type;
        this.tempFinalAmt = this.pageType === 'Update' ? this.modelValue.total_item_sale_amount : this.tempFinalAmt;
        if (type === '%') {
            if (this.modelValue.total_item_discount_percent || (`${this.modelValue.total_item_discount_percent}` === '0' && this.modelValue.total_item_sale_amount)) {
                this.modelValue.total_item_discount_amount = parseFloat((((this.modelValue.total_item_discount_percent / 100) * this.tempFinalAmt)).toFixed(2));
                this.modelValue.total_item_amount = (this.tempFinalAmt - this.modelValue.total_item_discount_amount).toFixed(2);
            } else {
                this.modelValue.total_item_discount_amount = 0;
                this.modelValue.total_item_discount_percent = 0;
            }
        } else {
            if (this.modelValue.total_item_discount_amount) {
                this.modelValue.total_item_discount_percent = parseFloat((((this.modelValue.total_item_discount_amount * 100) / this.tempFinalAmt)).toFixed(2));
                if (this.modelValue.total_item_discount_percent > 100) {
                    this.modelValue.total_item_discount_percent = 100;
                }
                this.modelValue.total_item_discount_amount = parseFloat((((this.modelValue.total_item_discount_percent / 100) * this.tempFinalAmt)).toFixed(2));
                this.modelValue.total_item_amount = (this.tempFinalAmt - this.modelValue.total_item_discount_amount).toFixed(2);
            } else {
                if (this.modelValue.total_item_sale_amount && `${this.modelValue.total_item_discount_percent}` === '100') {
                    this.modelValue.total_item_discount_amount = this.modelValue.total_item_sale_amount;
                } else {
                    this.modelValue.total_item_discount_amount = 0;
                    this.modelValue.total_item_discount_percent = 0;
                }
            }
        }
        this.setFinalTotalAmt();
    }

    setFinalTotalAmt () {
        this.modelValue.bill_amount = Math.round(parseFloat(this.modelValue.total_item_amount)).toFixed(2);
        this.modelValue.roundoff_amount = (parseFloat(this.modelValue.bill_amount) - parseFloat(this.modelValue.total_item_amount)).toFixed(2);
        this.modelValue.amount_received = this.modelValue.bill_amount ? parseFloat(this.modelValue.bill_amount) : 0;
        this.changeReceiveAmt();
        if (this.modelValue.retBillAmt) {
            this.modelValue.payable_amount = Math.round(parseFloat(this.modelValue.bill_amount) - parseFloat(this.modelValue.retBillAmt)).toFixed(2);
        }
    }

    removeProduct (prodIndex) {
        // If the create sale product items has more than 1 means, We should allow to delete.
        // If pharmacy indent allow sale then we need to allow to remove all the products.
        if (this.modelValue[this.arrName].length > 1 || (this.pharmacyIndentId && this.modelValue[this.arrName].length >= 1)) {
            const prodId = this.modelValue[this.arrName][prodIndex].sale_item_id;

            // If the delete icon click row product has id means, We should call delete sale item API with that
            if (prodId) {
                const self = this;
                this.saleItemDeleteLoading = true;
                // The below 250ms set timeout for delete icon continuous click issue fix.
                clearTimeout(this.saleItemTimeoutId);
                this.saleItemTimeoutId = setTimeout(() => {
                    if (self.saleItemSub !== null) {
                        self.saleItemSub.unsubscribe();
                    }
                    self.saleItemSub = self.saleItemRemoveService.deleteModel(prodId).pipe(
                        finalize(() => {
                            self.saleItemSub = null;
                            self.saleItemDeleteLoading = false;
                        })
                    ).subscribe((res: any) => {
                        // Because we have different data type responses
                        if ((typeof res === 'boolean' && res) || res.success) {
                            self.removeProd(prodIndex, prodId);
                        } else {
                            self.globalHelper.toastrOpen('E', res['message']);
                        }
                    });
                }, 250);
            } else {
                this.removeProd(prodIndex);
            }
        }
    }

    removeProd (prodIndex, prodId = null) {
        const self = this, prodItems = this.modelValue[this.arrName][prodIndex];
        if (prodItems && prodItems['product_id']) {
            this.updateBatchData('delete');
        }
        setTimeout(() => {
            if (prodId !== null) {
                const prodItemIndex = self.modelValue[self.arrName].findIndex(prodItem => prodItem.sale_item_id === prodId);
                if (prodItemIndex !== -1) {
                    self.modelValue[self.arrName].splice(prodItemIndex, 1);
                }
            } else {
                self.modelValue[self.arrName].splice(prodIndex, 1);
            }
            if (prodIndex === 0 && self.modelValue[self.arrName].length === 0 && !this.pharmacyIndentId) {
                self.modelValue[self.arrName].push({
                    id: self.globalHelper.guidGenerator(),
                    selectBatch: '',
                    quantity: 0,
                    hsn_no: '',
                    discount_percentage: 0,
                    discount_amount: 0
                });
            } else {
                self.prodFieldsVal = self.modelValue[self.arrName].every(prod => prod.selectProduct && prod.selectBatch);
                self.getFinalTotalAmt();
            }
        }, 250);
    }

    changeReceiveAmt () {
        this.modelValue.balance = this.globalHelper.toFixedWithZero(
            parseFloat(this.modelValue.amount_received) - parseFloat(this.modelValue.bill_amount),
            2
        );
    }

    async genericProducts (generic_id, prod_id) {
        const self = this,
            paramsOptions = this.globalHelper.convertObjectToParams({
                select: 'product_id,product_name,sale_product_name_with_qty,available_qty',
                generic_id
            });

        await this.productService.getProductsByGenric({ params: paramsOptions }).subscribe((genericProducts: any) => {
            self.generic_Products = genericProducts.productList.filter(prodData => prodData.product_id !== prod_id);
        });
    }

    addProduct () {
        this.prodFieldsVal = this.modelValue[this.arrName] && this.modelValue[this.arrName].every(prod => prod.selectProduct && prod.selectBatch);
        if (this.prodFieldsVal) {
            this.modelValue[this.arrName].push({id: this.globalHelper.guidGenerator(), selectBatch: '', quantity: 0, hsn_no: '', discount_percentage: 0, discount_amount: 0});
            const self = this;
            setTimeout(() => {
                self.inputFocusSelect();
                $(`#selectProduct${self.modelValue[self.arrName].length - 1}`).focus();
            }, 150);
        }
    }

    getPatLatestSale (openSale) {
        this.modelService.getPatientLatestSale(openSale, this.modelValue, this.laststBillLoading, this.billModel);
    }

    selectedBill (sale_id: any) {
        this.returnsaleLoading = true;
        if (sale_id !== null) {
            const self = this,
                paramsOptions = self.globalHelper.convertObjectToParams({ visible_columns: 'items,totalReturnQuantity,saleReturn' });
            this.saleRTNService.getSaleBill(sale_id, { params: paramsOptions }).subscribe((saleDetails: any) => {
                if (saleDetails) {
                    self.modelService.valueAssignedModel(self.modelValue, saleDetails);
                    setTimeout(() => {
                        self.saleReturnBillsFormElemts.saleReturnData = self.modelValue.current_sale_return_data;
                        self.saleReturnItemsFormElemts.saleReturnData = self.modelValue.current_sale_return_data;
                    });
                    self.returnsaleLoading = false;
                }
            }, error => {
                self.returnsaleLoading = false;
            });
        } else {
            this.modelValue.isRemoveSaleBill = false;
            this.returnsaleLoading =  false;
        }
    }

    async getMasterRecords () {
        const self = this,
            paramsOptions = this.globalHelper.convertObjectToParams({status: 1});
        await this.hsnCodeService.getModelLists({ params: paramsOptions }).subscribe((allHSNCodes: any) => {
            self.allHSNCodes = allHSNCodes;
        });
    }

}
