import { Component, OnInit, OnDestroy, EventEmitter, Output, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';
import * as Rx from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PatientService } from '../../modules/patient/services/patient.service';
import { GlobalHelper } from '../../helper/global.helper';
import { AppSettingService } from 'src/app/modules/configuration/app-settings/services/app-setting.service';

declare var require: any;
const PromisePool = require('es6-promise-pool');

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css'],
    providers: [
        PatientService,
        AppSettingService
    ]
})
export class NavigationComponent implements OnInit, OnDestroy {

    constructor(public router: Router, private modelService: PatientService,
        public globalHelper: GlobalHelper, private siteTitle: Title,
        private appSettingService: AppSettingService) {}

    @ViewChild('notifyDpDn') notifyDpDn: NgbDropdown;
    @Output() toggleSidebar = new EventEmitter<void>();

    patientSearchSub: Subscription;
    changeTimer;
    patientDetails;
    globalWatcher;
    public config: PerfectScrollbarConfigInterface = {};
    searchLoading = false;
    viewPatAccess = false;
    curUserName = '';
    curTenant = '';
    defaultPath = {
        pharmacy: '/pharmacy/dashboard',
        configuration: '/configuration/organization'
    };
    allTenants = [];
    searchResults = [];
    model: any = {};
    skeletonLoading = true;
    @HostListener('document:keyup', ['$event'])onEvent(event: KeyboardEvent) {
        if (event.key === 'Escape' || event.key === 'Esc') { this.globalHelper.showSearch = false; }
    }

    ngOnInit() {
        const userAccessUrls = this.globalHelper.getCurData('user-access-urls');
        if (Array.isArray(userAccessUrls) && userAccessUrls.length) {
            this.viewPatAccess = userAccessUrls.some(uAccData => uAccData.resource_name === 'Details');
        }
        this.curUserName = this.globalHelper.getCurData('curUserName');
        this.curTenant = this.globalHelper.getCurData('curTenantName');
        if (this.globalHelper.checkUserAccess('pharmacy/create-sale')) {
            this.defaultPath.pharmacy = '/pharmacy/create-sale';
        }
        if (this.globalHelper.checkUserAccess('configuration/roles')) {
            this.defaultPath.configuration = '/configuration/roles';
        }
        const self = this, vusmVal = this.globalHelper.getCurData('vusm');
        if (vusmVal) {
            setTimeout(() => {
                self.globalHelper.toastrOpen('S', 'New version updated successful!');
            });
            this.globalHelper.lsPush(this.globalHelper.getConstantsValue('app').versionUpdateSuccessMsg, false);
        }
        this.globalWatcher = this.globalHelper.watchStorage().subscribe((results: any) => {
            self.patientDetails = results;
        });
        this.lsWatch();
        this.getMasterRecords();
        $(document).mouseup((evtDatas: any) => {
            if (!$('.app-search').is(evtDatas.target) &&
                $('.app-search').has(evtDatas.target).length === 0) {
                self.globalHelper.showSearch = false;
            }
        });
    }

    lsWatch () {
        const self = this, appVariables = self.globalHelper.getConstantsValue('app');
        Rx.fromEvent<StorageEvent>(window, 'storage').pipe(
            filter(evt => evt.key === appVariables.currentUser),
            filter(evt => evt.newValue !== null && evt.oldValue !== null && JSON.parse(evt.newValue).tenant_id !== JSON.parse(evt.oldValue).tenant_id),
            map(async (evt) => {
                const newDatas = JSON.parse(evt.newValue);
                await self.globalHelper.updateCurData({tenant_id: newDatas.tenant_id, tenant_name: newDatas.tenant_name});
                self.checkAccessAndReload();
            }),
        ).subscribe();
        Rx.fromEvent<StorageEvent>(window, 'storage').pipe(
            filter(evt => evt.key === appVariables.currentUser),
            filter(evt => evt.newValue !== null && evt.oldValue !== null && JSON.parse(evt.newValue).color !== JSON.parse(evt.oldValue).color),
            map(async (evt) => {
                self.globalHelper.userThemeOptions = JSON.parse(evt.newValue).color;
                self.setTheme();
            }),
        ).subscribe();
        Rx.fromEvent<StorageEvent>(window, 'storage').pipe(
            filter(evt => evt.key === appVariables.appVersion),
            filter(evt => evt.newValue !== null && evt.oldValue !== null && JSON.parse(evt.newValue) !== JSON.parse(evt.oldValue)),
            map(async (evt) => {
                $('.update-alert').css('display', 'none');
                $('.update-alert').removeClass('open-update-alert');
                $('#version-value').text('');
                $('#version-update-msg').text('');
            }),
        ).subscribe();
    }

    openSearchBox () {
        this.globalHelper.showSearch = true;
        setTimeout(() => {
            document.getElementById('searchBox').focus();
        });
    }

    setTheme () {
        if (this.globalHelper.userThemeOptions) {
            this.globalHelper.updateCurData({color: this.globalHelper.userThemeOptions});
            const bodyClass = document.getElementsByTagName('body')[0]['className'],
            themeClassIndex = bodyClass.search('main-theme-');
            if (themeClassIndex !== -1) {
                document.getElementsByTagName('body')[0]['className'] = bodyClass.replace(bodyClass.substring(themeClassIndex, themeClassIndex + 12), '');
            }
            document.getElementsByTagName('body')[0]['className'] += ' ' + this.globalHelper.userThemeOptions.themeClass;
        }
    }
    handlePaste(event: ClipboardEvent) {
        const self = this,
            inputElement = event.target as HTMLInputElement,
            currentValue = inputElement.value, // Get the current value before paste
            pastedText = event.clipboardData.getData('text') || '', // Get pasted text
            selectionStart = inputElement.selectionStart,
            selectionEnd = inputElement.selectionEnd;

        // Use setTimeout to allow the paste to complete and the value to update
        setTimeout(() => {
            const updatedValue = currentValue.substring(0, selectionStart) + pastedText + currentValue.substring(selectionEnd); // Combine pasted text with current value
            self.model.search = updatedValue;
            self.siteSearch(updatedValue);
        }, 0);
    }

    handleCut(event: ClipboardEvent) {
        const self = this,
            inputElement = event.target as HTMLInputElement,
            currentValue = inputElement.value, // Get the current value before cut
            selectionStart = inputElement.selectionStart,
            selectionEnd = inputElement.selectionEnd;

        // Use setTimeout to allow the cut to complete and the value to update
        setTimeout(() => {
            const updatedValue = currentValue.substring(0, selectionStart) + currentValue.substring(selectionEnd); // Get updated value after cut
            self.model.search = updatedValue;
            self.siteSearch(updatedValue);
        }, 0);
    }

    onKeyupForPatientSearch (e: any) {
        if (e) {
            const keyValue = e.target.value + e.key;
            if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArroDown', 'Tab', 'Alt', 'Shift', 'Control', 'Enter', 'Meta'].includes(e.key)) {
                return;
            }
            if (!/^[a-zA-Z0-9\b ]+$/.test(keyValue) && e.keyCode !== 46) {
                return false;
            }
            if (e.keyCode === 106 || e.keyCode === 107 || e.keyCode === 109 || e.keyCode === 111) {
                return false;
            }
            if (((e && e.key && (e.key.toUpperCase() === 'C' || e.key.toUpperCase() === 'A')) && e.ctrlKey)) {
                return false;
            }
        }
        this.model.search = e.target.value;
        this.siteSearch();
    }

    siteSearch (search: any = this.model.search) {
        if (search.trim() !== '') {
            if (this.patientSearchSub) {
                this.patientSearchSub.unsubscribe();
            }
            if (this.changeTimer) {
                clearTimeout(this.changeTimer);
            }
            const self = this;
            this.searchLoading = true;
            this.skeletonLoading = true;
            this.changeTimer = setTimeout(() => {
                const paramsOptions = self.globalHelper.convertObjectToParams({ search });
                self.patientSearchSub = self.modelService.getPatientSearch({params: paramsOptions}).subscribe(async (results: any) => {
                    if (Array.isArray(results) && results.length) {
                        self.searchResults = results;
                        self.searchLoading = false;
                        self.getPatientTenants(self.searchResults);
                    } else {
                        self.searchLoading = false;
                        self.searchResults = [];
                    }
                }, error => {
                    self.searchLoading = false;
                });
                self.changeTimer = false;
            }, 300);
        } else {
            this.searchResults = [];
            this.searchLoading = false;
            clearTimeout(this.changeTimer);
        }
    }

    async getPatientTenants (data) {
        const self = this;
        const generatePromises = function * () {
            for (let i = 0; i < data.length; i++) {
                const rowData = data[i];
                yield self.modelService.getPatientTenantDetails(rowData.patient_global_guid).then((results: any) => {
                    if (Array.isArray(results) && results.length) {
                        const curTenantDatas = results.filter(patTenant => patTenant.tenant_id === self.globalHelper.getCurData('curTenantId'));
                        rowData['curTenantDatas'] = curTenantDatas && curTenantDatas.length ? curTenantDatas[curTenantDatas.length - 1] : false;
                        rowData['patient_tenant'] = results || [];
                    }
                });
            }
        };

        const promiseIterator = generatePromises();
        const pool = new PromisePool(promiseIterator, 5);
        pool.start()
            .then(() => { })
            .catch((err: any) => { console.log(err); })
            .finally(() => {
                this.skeletonLoading = false;
            });
    }

    goPatientLayout (curPatientDatas, patientPage) {
        if (curPatientDatas.curTenantDatas) {
            this.router.navigate(['patient/' + patientPage + '/' + curPatientDatas.curTenantDatas.patient_guid]);
            this.patientByGuid(curPatientDatas.curTenantDatas.patient_guid);
        } else {
            const self = this;
            this.modelService.importPatient({patient_global_guid: curPatientDatas.patient_global_guid}).subscribe((results: any) => {
                if (results.success) {
                    self.router.navigate(['patient/' + patientPage + '/' + results.patient.patient_guid]);
                    self.patientByGuid(results.patient.patient_guid);
                }
            });
        }
        this.globalHelper.showSearch = false;
        this.model.search = '';
    }

    patientByGuid (patientGUID) {
        const self = this;
        const appVars = this.globalHelper.getConstantsValue('app');
        this.modelService.getPatientByGuid({ guid: patientGUID }).subscribe((result: any) => {
            self.globalHelper.lsPush(appVars.patientDetails, JSON.stringify(result));
        }, error => {
            self.router.navigate(['configuration']);
        });
    }

    changeTenant () {
        const self = this;
        $('.full-loading').show();
        $('body').css('overflow-y', 'hidden');
        this.modelService.switchUserBranch(this.model).subscribe(async (results: any) => {
            await self.globalHelper.lsPush(
                self.globalHelper.getConstantsValue('app').userAccessURLs,
                JSON.parse(JSON.stringify({userAccessDatas: results.resources}))
            );
            self.curTenant = await self.allTenants.filter(tenantData =>
                tenantData.tenant_id === self.model.branch_id)[0].tenant_name;
            await self.globalHelper.updateCurData({tenant_id: self.model.branch_id, tenant_name: self.curTenant});
            // App setting application status API call after branch changed
            await self.appSettingService.getApplicationStatus().subscribe(() => {
                self.checkAccessAndReload(results.resources);
            }, (err) => self.checkAccessAndReload(results.resources));
        });
    }

    checkAccessAndReload (resources?) {
        resources = resources || this.globalHelper.getCurData('user-access-urls');
        const checkAccResUrl = this.globalHelper.getPatModCurPath();
        if (checkAccResUrl && (Array.isArray(resources) &&
            resources.some(userAccDatas => userAccDatas.resource_url === checkAccResUrl))) {
            if (checkAccResUrl.split('/')[0] === 'patient') {
                const self = this, curPatDetails = this.globalHelper.getCurData('pat-dtl');
                this.modelService.patImportNewBranch({
                    tenant_id: this.model.branch_id,
                    patient_global_guid: curPatDetails.patient_global_guid
                }).subscribe(async (results: any) => {
                    if (results.success === true) {
                        await self.patientByGuid(results.patient.patient_guid);
                        await self.getMasterRecords();
                        self.globalHelper.changePatient(results.patient.patient_guid);
                        $('.full-loading').hide();
                        $('body').css('overflow-y', 'scroll');
                    } else {
                        location.reload();
                    }
                }, (error) => {
                    location.reload();
                });
            } else {
                location.reload();
            }
        } else {
            this.globalHelper.checkAccessToGo();
            setTimeout(() => {
                location.reload();
            }, 200);
        }
    }

    highlightTerm (searchTerm) {
        const term = searchTerm.split(' ').map(termData => termData.trim() );
        return term;
    }

    logOut() {
        this.globalHelper.logout();
    }

    getMasterRecords () {
        const self = this;
        this.modelService.getUserTenants().subscribe((results: any) => {
            if (results) {
                self.globalHelper.userThemeOptions = JSON.parse(results.color);
                self.setTheme();
                self.allTenants = results.branches;
                const curTenant = self.globalHelper.getCurData('curTenantName');
                if (curTenant) {
                    self.siteTitle.setTitle(`Medizura - ${curTenant}`);
                }
                if (Array.isArray(results.notification) && results.notification.length) {
                    const updateNotifyIndex = results.notification.map(notifyDatas => notifyDatas.event).indexOf('UPDATE AVAILABLE');
                    if (updateNotifyIndex !== -1) {
                        const serverVersion = results.notification[updateNotifyIndex].value,
                            clientVersion = self.globalHelper.getCurData('appVersion');
                        if (clientVersion) {
                            if (serverVersion !== clientVersion) {
                                $('.update-alert').css('display', 'block');
                                $('.update-alert').addClass('open-update-alert');
                                $('#version-value').text(results.notification[updateNotifyIndex].value);
                                const verMsg = results.notification[updateNotifyIndex].message;
                                $('#version-update-msg').text(verMsg ? verMsg : '');
                            } else {
                                $('.update-alert').css('display', 'none');
                                $('.update-alert').removeClass('open-update-alert');
                                $('#version-value').text('');
                                $('#version-update-msg').text('');
                            }
                        } else {
                            self.globalHelper.lsPush(self.globalHelper.getConstantsValue('app').appVersion, serverVersion);
                        }
                    }
                }
                self.model.branch_id = results.default_branch;
                self.globalHelper.lsPush(self.globalHelper.getConstantsValue('app').userAccessURLs, {userAccessDatas: results.resources});
            }
        });
    }

    ngOnDestroy () {
        if (this.globalWatcher) { this.globalWatcher.unsubscribe(); }
    }

    closeSearchDropdown () {
        this.globalHelper.showSearch = false;
        this.model.search = '';
        this.searchResults = [];
    }

}
